import React, {useState, useEffect} from 'react'
import { Carousel } from 'flowbite-react'
import { Link, useNavigate } from 'react-router-dom'
import accent1 from '../assets/Accent1.webp'
import accent2 from '../assets/Accent2.webp'
import accent3 from '../assets/Accent3.webp'
import clientbg from '../assets/client-background.webp'
import bajau25 from '../assets/Bajau25.webp'
import taglinePic from '../assets/home/taglinePic.webp'
import clientCentered from '../assets/home/Client Centered.webp'
import deliverResults from '../assets/home/Deliver Results.webp'
import trustedPartner from '../assets/home/Trusted Partnership.webp'
import placeholderimg from '../assets/placeholder-image.webp'
import client1 from '../assets/clients/Client_UOB.webp'
import client2 from '../assets/clients/Client_Permata.webp'
import client3 from '../assets/clients/Client_Danamon.webp'
import client4 from '../assets/clients/Client_Icon+.webp'
import client5 from '../assets/clients/Client_Panin.webp'
import client6 from '../assets/clients/Client_Coca.webp'
import client7 from '../assets/clients/Client_Telkomsel.webp'
import client8 from '../assets/clients/Client_Sumber.webp'
import client9 from '../assets/clients/Client_Sampoerna.webp'
import client10 from '../assets/clients/Client_BCA.webp'
import client11 from '../assets/clients/Client_BI.webp'
import client12 from '../assets/clients/Client_CIMB.webp'
import client13 from '../assets/clients/Client_BTN.webp'
import client14 from '../assets/clients/Client_Chubb.webp'
import client15 from '../assets/clients/Client_KDDI.webp'
import client16 from '../assets/clients/Client_Sequis.webp'
import client17 from '../assets/clients/Client_Tokio.webp'
import client18 from '../assets/clients/Client_Mandiri.webp'
import client19 from '../assets/clients/Client_Astra.webp'
import client20 from '../assets/clients/Client_Citra.webp'
import client21 from '../assets/clients/Client_Indihome.webp'
import client22 from '../assets/clients/Client_BankRaya.webp'
import client23 from '../assets/clients/Client_Harrisma.webp'
import client24 from '../assets/clients/Client_Cashlez.webp'
import principalsData from '../helpers/PrincipalsData.json'
import Button from '../components/Button'
import { useTranslation } from 'react-i18next'

function HomeContents() {
    const [firstRowArr, setFirstRowArr] = useState([client1, client2, client3, client4, client5, client6, client7, client8, client9, client10, client11, client12, client13]);
    const [secondRowArr, setSecondRowArr] = useState([client14, client15, client16, client17, client18, client19, client20, client21, client22, client23, client24])
    const [solutionIndex, setSolutionIndex] = useState(0)

    const {t} = useTranslation("home")

    const navigate = useNavigate()

    const topSolutions = [
        {solution: "High-Availability Database", description: t("solutionsAndServices.High-Availability Database"), image: require("../assets/solutions/High_Availability.webp"), to:"/solutions-and-services/solutions/Backups & Disaster Recovery/High-Availability Database"},
        {solution: "Service Orchestration and Automation", description: t("solutionsAndServices.Service Orchestration and Automation"), image:require("../assets/solutions/Service_Orchestration.webp"), to:"/solutions-and-services/solutions/Workload Automation/Service Orchestration and Automation"},
        {solution: "System Provisioning and Configuration Management", description: t("solutionsAndServices.System Provisioning and Configuration Management"), image:require("../assets/solutions/System_Provisioning.webp"), to:"/solutions-and-services/solutions/Infrastructure & Security Solutions/System Provisioning and Configuration Management"},
        {solution: "Enterprise File Sync and Sharing (EFSS)", description: t("solutionsAndServices.Enterprise File Sync and Sharing (EFSS)"), image:require("../assets/solutions/Enterprise_File_Sync_Share.webp"), to:"/solutions-and-services/solutions/Enterprise Cloud Solutions/Enterprise File Sync and Sharing (EFSS)"},
        {solution: "Software Automation Testing", description: t("solutionsAndServices.Software Automation Testing"), image:require("../assets/solutions/Software_Automation_Testing.webp"), to:"/solutions-and-services/solutions/Software Automation Testing"},
    ]

    function hoverSolution(index) {
        setSolutionIndex(index)
    }

    function navToAboutUs() {
        navigate('/about-us')
    }

    function navToBlog() {
        navigate('/news/25 Tahun Bajau Escorindo: Sejarah, Masa Kini, dan Harapan untuk Masa Depan')
    }

    function shuffle(array) {
        let newArray = [...array]; // Create a copy to avoid mutating the original array
        let currentIndex = newArray.length;
    
        while (currentIndex !== 0) {
          let randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [newArray[currentIndex], newArray[randomIndex]] = [
            newArray[randomIndex],
            newArray[currentIndex],
          ];
        }
        return newArray;
      }
    
      useEffect(() => {
        const interval = setInterval(() => {
          setFirstRowArr((prevArr) => shuffle(prevArr)); // Shuffle the array and update state
          setSecondRowArr((prevArr) => shuffle(prevArr)); // Shuffle the array and update state
        }, 25000);
    
        return () => clearInterval(interval); // Cleanup the interval on unmount
      }, []);

    const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-blueaccent",
          },
          base: "h-3 w-3 rounded-full",
          wrapper: "absolute bottom-0 gap-4 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
            base: "pb-6 mx-auto" 
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full lg:group-hover:bg-blueprimary/50 bg-blueaccent/50 lg:bg-transparent group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 lg:text-blueprimary text-white dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };
    
      const customThemeNews = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "hidden",
          rightControl:
            "hidden",
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden",
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
            base: "mx-auto" 
        },
        control: {
            base: "hidden",
            icon: "hidden"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };

  return (
    <div className='flex flex-grow w-full flex-col items-center pt-20'>
        <div className='hidden md:block w-full bg-blueprimary h-14'>
            <Carousel theme={customThemeNews} pauseOnHover>
                <div className='lg:w-4/6 w-11/12 h-full flex justify-center items-center gap-5'>
                    <div className='py-2 px-6 bg-white text-blue-950 font-plusjakarta font-bold rounded-full'>{t("news.tagline").toUpperCase()}</div>
                    <Link to={"/news/25 Tahun Bajau Escorindo: Sejarah, Masa Kini, dan Harapan untuk Masa Depan"}><span className='text-white font-raleway hover:underline hover:cursor-pointer'>25 Tahun Bajau Escorindo: Sejarah, Masa Kini, dan Harapan untuk Masa Depan</span></Link>
                </div>
                <div className='lg:w-4/6 w-11/12 h-full flex justify-center items-center gap-5'>
                    <div className='py-2 px-6 bg-white text-blue-950 font-plusjakarta font-bold rounded-full'>{t("news.tagline").toUpperCase()}</div>
                    <Link to={"/news/Gen-Z Bisa Apa? Bisa Bikin Website Baru untuk HUT 25 Tahun Bajau!"}><span className='text-white font-raleway hover:underline hover:cursor-pointer'>Gen-Z Bisa Apa? Bisa Bikin Website Baru untuk HUT 25 Tahun Bajau!</span></Link>
                </div>
            </Carousel>
        </div>
        <div className='md:hidden w-full bg-blueprimary h-14'>
            <Carousel theme={customThemeNews} pauseOnHover>
                <div className='lg:w-4/6 w-11/12 h-full flex justify-center items-center gap-5'>
                    <div className='py-2 px-6 bg-white text-blue-950 font-plusjakarta font-bold rounded-full'>{t("news.tagline").toUpperCase()}</div>
                    <Link to={"/news/25 Tahun Bajau Escorindo: Sejarah, Masa Kini, dan Harapan untuk Masa Depan"}><span className='text-white font-raleway hover:underline hover:cursor-pointer'>25 Tahun Bajau Escorindo ... </span></Link>
                </div>
                <div className='lg:w-4/6 w-11/12 h-full flex justify-center items-center gap-5'>
                    <div className='py-2 px-6 bg-white text-blue-950 font-plusjakarta font-bold rounded-full'>{t("news.tagline").toUpperCase()}</div>
                    <Link to={"/news/Gen-Z Bisa Apa? Bisa Bikin Website Baru untuk HUT 25 Tahun Bajau!"}><span className='text-white font-raleway hover:underline hover:cursor-pointer'>Gen-Z Bisa Apa? Bikin Bi ... </span></Link>
                </div>
            </Carousel>
        </div>
        <div className='lg:h-screen lg:pb-32 h-[500px] w-full relative'>
            <div className='-z-10 absolute lg:pt-40 pt-5'>
                <img src={accent1} style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <Carousel theme={customTheme} slideInterval={5000}>
            <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-center justify-center'>
                            <div className='w-full lg:w-3/4 flex flex-col gap-10'>
                                <div className='flex flex-col gap-4'>
                                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl'>{t("banner.bajau25.title")}</span>
                                    <span className='font-raleway'>{t("banner.bajau25.tagline")}</span>
                                </div>
                                <Button label={t("readMore")} onClick={navToBlog} />
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={bajau25} className='lg:object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-center justify-center'>
                            <div className='w-full lg:w-3/4 flex flex-col gap-10'>
                                <div className='flex flex-col gap-4'>
                                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl'>{t("banner.strategic.title")}</span>
                                    <span className='font-raleway'>{t("banner.strategic.tagline")}</span>
                                </div>
                                <Button label={t("readMore")} onClick={navToAboutUs} />
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={taglinePic} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-center justify-center'>
                            <div className='w-full lg:w-3/4 flex flex-col gap-10'>
                                <div className='flex flex-col gap-4'>
                                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl'>{t("banner.clientCentered.title")}</span>
                                    <span className='font-raleway'>{t("banner.clientCentered.tagline")}</span>
                                </div>
                                <Button label={t("readMore")} onClick={navToAboutUs} />
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={clientCentered} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-center justify-center'>
                            <div className='w-full lg:w-3/4 flex flex-col gap-10'>
                                <div className='flex flex-col gap-4'>
                                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl'>{t("banner.trusted.title")}</span>
                                    <span className='font-raleway'>{t("banner.trusted.tagline")}</span>
                                </div>
                                <Button label={t("readMore")} onClick={navToAboutUs} />
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={trustedPartner} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-center justify-center'>
                            <div className='w-full lg:w-3/4 flex flex-col gap-10'>
                                <div className='flex flex-col gap-4'>
                                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl'>{t("banner.deliverResult.title")}</span>
                                    <span className='font-raleway'>{t("banner.deliverResult.tagline")}</span>
                                </div>
                                <Button label={t("readMore")} onClick={navToAboutUs} />
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={deliverResults} className='object-cover w-full' alt=''/>
                    </div>
                </div>
            </Carousel>
        </div>
        <div className='h-auto lg:pb-40 lg:pt-16 py-24 flex justify-center items-center w-full relative'>
            <div className='-z-10 absolute w-full flex justify-center items-center'>
                <img src={accent2} className='w-1/2 h-1/2' style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <div className='lg:w-3/6 w-11/12 h-auto flex flex-col lg:gap-12 gap-5'>
                <div className='flex flex-col gap-1'>
                    <span className='font-plusjakarta text-blueaccent'>{t("solutionsAndServices.tagline")}</span>
                    <span className='font-plusjakarta font-bold lg:text-5xl text-2xl text-bluehover'>{t("solutionsAndServices.title")}</span>
                </div>
                <div className='h-full w-full flex gap-5'>
                    <div className='w-full h-full flex flex-col gap-2' style={{animation: `appear-left linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                        {topSolutions.map(({solution, to}, index) => (
                            <Link to={to} key={index} className={`${index === solutionIndex ? 'lg:bg-blue-900 lg:font-bold lg:text-white lg:scale-105 lg:cursor-pointer bg-neutralgray' : 'bg-neutralgray'} w-full rounded-md shadow-md px-4 h-12 flex items-center font-raleway`} onMouseEnter={() => {hoverSolution(index)}}>{solution}</Link>
                        ))}
                    </div>
                    <Link to={topSolutions[solutionIndex].to} className='hidden lg:w-full lg:rounded-md lg:bg-cover lg:bg-center lg:flex lg:flex-col lg:justify-end lg:hover:cursor-pointer' style={{backgroundImage: `url(${topSolutions[solutionIndex].image})`, backgroundSize: 'cover', animation: `appear-right linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                        <div className='w-full h-auto font-raleway text-white p-5 bg-black bg-opacity-75 rounded-md'>{topSolutions[solutionIndex].description}</div>
                    </Link>
                </div>
            </div>
        </div>
        <div className='relative h-auto lg:py-40 py-10 flex justify-center items-center w-full bg-cover bg-center' style={{backgroundImage: `url(${clientbg})`, backgroundSize:`cover`}}>
            <div className='absolute bg-white inset-0 opacity-80 w-full h-full'></div>
            <div className='relative lg:w-4/6 lg:h-4/6 flex flex-col lg:gap-12 gap-2 w-11/12'>
                <div className='flex flex-col gap-1' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <span className='font-plusjakarta text-blueaccent'>{t("products.tagline")}</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-bluehover'>{t("products.title")}</span>
                </div>
                <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <Carousel pauseOnHover theme={customTheme} className='h-80'>
                        {principalsData.map((data,index) => (
                            <div key={index} className='lg:w-4/5 lg:h-80 lg:flex lg:flex-row lg:gap-12 lg:justify-center lg:items-center w-8/12 grid grid-cols-2 place-items-center gap-2'>
                                {data.map((principal, principalIndex) => (
                                    <Link to={principal.link} className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center' key={principalIndex}><img src={principal.image ? require(`../assets/principals/${principal.image}`) : placeholderimg} alt='' className='w-full'/></Link>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
        <div className='h-auto lg:py-44 py-24 flex justify-center items-center w-full relative overflow-x-hidden'>
            <div className='-z-10 absolute w-full flex justify-end items-center'>
                <img src={accent3} className='w-1/4 h-1/4' style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <div className='w-full h-auto flex flex-col gap-12 items-center'>
                <div className='lg:w-4/6 w-11/12 flex flex-col gap-1'>
                    <span className='font-plusjakarta text-blueaccent'>{t("clients.tagline")}</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-bluehover'>{t("clients.title")}</span>
                </div>
                <div className='w-full h-auto flex flex-col gap-10'>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            {firstRowArr.map((data,index) => (
                                <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className={data === client7 || data === client8 || data === client10 || data === client12 ? `w-full object-contain` : `h-full object-contain`} alt=''/> </div>
                            ))}
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            {firstRowArr.map((data,index) => (
                                <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className={data === client7 || data === client8 || data === client10 || data === client12 ? `w-full object-contain` : `h-full object-contain`} alt=''/> </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            {secondRowArr.map((data, index) => (
                                <div className='w-48 h-16 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className='h-full object-contain' alt=''/> </div>
                            ))}
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            {secondRowArr.map((data, index) => (
                                <div className='w-48 h-16 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className='h-full object-contain' alt=''/> </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeContents