import React from 'react'
import { Link, useParams } from 'react-router-dom'
import blogsData from '../helpers/BlogsData.json'
import placeholderimg from '../assets/placeholder-image.webp'
import { IoIosArrowBack } from "react-icons/io";
import accent1 from "../assets/Accent1.webp"

function ReadBlogContents() {
    const {title} = useParams()

    const blogIndex = blogsData[0].blogs.findIndex(item => item.title === title)
    const selectedBlog = blogIndex === -1 ? blogsData[0].blogs[1] : blogsData[0].blogs[blogIndex]

  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:py-40 py-24 relative'>
        <div className='lg:w-4/6 w-11/12 flex flex-col gap-12 relative'>
            <div className='w-full h-12 flex justify-center items-center absolute top-52 -z-10'>
                <img src={accent1} alt='' className='' style={{animation: `spin linear 20s infinite`}}/>
            </div>
            <div className='w-full lg:h-12 h-10 flex justify-start items-center text-blueprimary hover:text-bluehover'>
                <Link to={'/news'}><IoIosArrowBack className='h-full lg:w-12 w-10'/></Link>
            </div>
            <div className='w-full flex flex-col gap-10 justify-between items-center'>
                <div className='w-full lg:w-auto font-plusjakarta lg:text-4xl text-2xl font-bold'>{selectedBlog.title}</div>
                <div className='lg:w-3/4 lg:h-full w-full h-80 bg-slate-200 rounded-lg'>
                    <img src={selectedBlog.image ? require(`../assets/${selectedBlog.image}`) : placeholderimg} alt='' className='object-cover w-full h-full'/>
                </div>
            </div>
            <div className='w-full flex flex-col gap-8 font-raleway'>
                {selectedBlog.text.map((data, index) => (
                    <p key={index}>
                    {data.paragraph.map((part, index) => {
                      if (typeof part === 'string') {
                        return part; // Render plain text
                      } else if (part.bold && part.italic) {
                        return <strong key={index}><em>{part.bold}</em></strong>; // Render bold + italic
                      } else if (part.bold) {
                        return <strong key={index}>{part.bold}</strong>; // Render bold
                      } else if (part.italic) {
                        return <em key={index}>{part.italic}</em>; // Render italic
                      } else if (part.orderedList) {
                        return <>
                        {part.orderedList.map((data, index) => (
                            <p key={index}>{data}</p>
                        ))}
                        </>
                      }
                      return null;
                    })}
                  </p>
                    // <div key={index}>{data.paragraph}</div>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default ReadBlogContents