import React, {useState, useEffect} from 'react'
import accent3 from '../assets/Accent3.webp'
import placeholderimg from '../assets/placeholder-image.webp'
import { Carousel } from 'flowbite-react'
import accent4 from '../assets/Accent4.webp'
import { FaQuestion } from "react-icons/fa";
import vision from '../assets/about_us/Vision.webp'
import mission from '../assets/about_us/Mission.webp'
import coreValues from '../assets/about_us/Core Values.webp'
import { useTranslation } from 'react-i18next'
import client1 from '../assets/clients/Client_UOB.webp'
import client2 from '../assets/clients/Client_Permata.webp'
import client3 from '../assets/clients/Client_Danamon.webp'
import client4 from '../assets/clients/Client_Icon+.webp'
import client5 from '../assets/clients/Client_Panin.webp'
import client6 from '../assets/clients/Client_Coca.webp'
import client7 from '../assets/clients/Client_Telkomsel.webp'
import client8 from '../assets/clients/Client_Sumber.webp'
import client9 from '../assets/clients/Client_Sampoerna.webp'
import client10 from '../assets/clients/Client_BCA.webp'
import client11 from '../assets/clients/Client_BI.webp'
import client12 from '../assets/clients/Client_CIMB.webp'
import client13 from '../assets/clients/Client_BTN.webp'
import client14 from '../assets/clients/Client_Chubb.webp'
import client15 from '../assets/clients/Client_KDDI.webp'
import client16 from '../assets/clients/Client_Sequis.webp'
import client17 from '../assets/clients/Client_Tokio.webp'
import client18 from '../assets/clients/Client_Mandiri.webp'
import client19 from '../assets/clients/Client_Astra.webp'
import client20 from '../assets/clients/Client_Citra.webp'
import client21 from '../assets/clients/Client_Indihome.webp'
import client22 from '../assets/clients/Client_BankRaya.webp'
import client23 from '../assets/clients/Client_Harrisma.webp'
import client24 from '../assets/clients/Client_Cashlez.webp'

function AboutUsContents() {
    const [firstRowArr, setFirstRowArr] = useState([client1, client2, client3, client4, client5, client6, client7, client8, client9, client10, client11, client12, client13]);
    const [secondRowArr, setSecondRowArr] = useState([client14, client15, client16, client17, client18, client19, client20, client21, client22, client23, client24])

    const {t} = useTranslation("aboutUs")
    const {i18n} = useTranslation()
    const carouselContent = [
        {
            title: t("carouselTitle1"),
            subtitle: t("carouselTagline1"),
            image: "Our History.webp",
            text: [
                { paragraph: t("carouselParagraph1.paragraph1")}
            ]
        },
        {
            title: t("carouselTitle2"),
            subtitle: t("carouselTagline2"),
            image: "History Name Bajau.webp",
            text: [
                { paragraph: t("carouselParagraph2.paragraph1")},
                { paragraph: t("carouselParagraph2.paragraph2")}
            ]
        }
    ]

    function shuffle(array) {
        let newArray = [...array]; // Create a copy to avoid mutating the original array
        let currentIndex = newArray.length;
    
        while (currentIndex !== 0) {
          let randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [newArray[currentIndex], newArray[randomIndex]] = [
            newArray[randomIndex],
            newArray[currentIndex],
          ];
        }
        return newArray;
      }
    
      useEffect(() => {
        const interval = setInterval(() => {
          setFirstRowArr((prevArr) => shuffle(prevArr)); // Shuffle the array and update state
          setSecondRowArr((prevArr) => shuffle(prevArr)); // Shuffle the array and update state
        }, 25000);
    
        return () => clearInterval(interval); // Cleanup the interval on unmount
      }, []);

    const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-black",
          },
          base: "h-2 w-2 rounded-full bottom-0",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-blueprimary/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 text-blueprimary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };
  return (
    <>
    <div className='flex flex-grow w-full flex-col justify-center items-center lg:pt-40 py-24 gap-12'>
        <div className='w-full lg:h-[500px] h-[850px] relative flex justify-start'>
            <div className='-z-10 absolute w-full flex justify-center items-center h-full'>
                <img src={accent4} style={{animation: `spin linear 20s infinite`}} className='w-1/4' alt=''/>
            </div>
            <Carousel slide={false} className="w-full" theme={customTheme}>
                {carouselContent.map((content, index) => (
                    <div key={index} className='lg:w-4/6 lg:flex lg:flex-row gap-5 flex flex-col-reverse w-11/12 lg:p-0'>
                        <div className='w-full h-full flex flex-col gap-12'>
                            <div className='flex flex-col gap-1'>
                                <span className='font-plusjakarta text-blueaccent'>{content.subtitle}</span>
                                <span className='font-plusjakarta font-bold lg:text-4xl text-xl text-bluehover'>{content.title}</span>
                            </div>
                            <div className='flex flex-col gap-4 mb-8 lg:mb-0'>
                                {content.text.map((data, index) => (
                                    <div key={index} className='font-raleway'>{data.paragraph}</div>
                                ))}
                            </div>
                        </div>
                        <div className='h-full w-full'>
                            <img src={content.image ? require(`../assets/about_us/${content.image}`) : placeholderimg} className='object-cover h-full w-full rounded-xl' alt=''/>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
        <div className='lg:w-3/6 w-11/12 h-auto flex flex-col gap-1 lg:py-24 py-10'>
            <span className='font-plusjakarta text-blueaccent'>{t("visionAndMission.sectionTitle")}</span>
            <div className='w-full flex flex-col gap-12'>
                <div className='lg:flex lg:flex-row flex flex-col gap-5 lg:gap-8' style={{animation: `appear-right linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <div className='lg:w-2/5 w-full'>
                        <img src={vision} className='lg:h-60 rounded-xl' alt=''/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-xl text-bluehover'>{t("visionAndMission.visionTitle")}</span>
                        <span className='font-raleway'>{t("visionAndMission.vision")}</span>
                    </div>
                </div>
                <div className='lg:flex lg:flex-row flex flex-col-reverse gap-5 lg:gap-8' style={{animation: `appear-left linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <div className='flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-bluehover'>{t("visionAndMission.missionTitle")}</span>
                        <span className='font-raleway'>{t("visionAndMission.mission.line1")}</span>
                        <span className='font-raleway'>{t("visionAndMission.mission.line2")}</span>
                        <span className='font-raleway'>{t("visionAndMission.mission.line3")}</span>
                        <span className='font-raleway'>{t("visionAndMission.mission.line4")}</span>
                        <span className='font-raleway'>{t("visionAndMission.mission.line5")}</span>
                    </div>
                    <div className='lg:w-2/5 w-full'>
                        <img src={mission} className='lg:h-60 rounded-xl' alt=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full flex flex-col items-center relative'>
            <div className='absolute -z-10 w-full h-full'><img src={coreValues} alt='' className='w-full h-full' /></div>
            <div className='w-full h-full bg-black opacity-75 flex flex-col items-center justify-center'>
                <div className='flex flex-col my-10 gap-1 lg:w-4/6 w-11/12'>
                    <span className='font-plusjakarta text-blueaccent'>{t("coreValues.tagline")}</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-white'>{t("coreValues.title")}</span>
                </div>
                <div className='lg:w-full w-11/12 lg:flex lg:flex-row flex flex-col gap-2 h-full'>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>{t("coreValues.value1.title")}</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>{t("coreValues.value1.description")}</span>
                        </div>
                    </div>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>{t("coreValues.value2.title")}</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>{t("coreValues.value2.description")}</span>
                        </div>
                    </div>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>{t("coreValues.value3.title")}</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>{t("coreValues.value3.description")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full lg:py-24 py-16 bg-bluehover flex justify-center items-center'>
                <div className='flex flex-col lg:w-3/6 w-11/12 items-center gap-10 relative'>
                    <div className='absolute w-full h-full flex justify-center items-center'>
                        <div className='h-60 w-80 rounded-lg absolute'>
                            <FaQuestion className='h-full w-full text-blueaccent opacity-20 blur-md'/>
                        </div>
                    </div>
                    <span className='font-plusjakarta lg:text-2xl text-xl font-bold text-white' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>{t('whyChooseUs.title')}</span>
                    <div className='flex flex-col gap-6 font-raleway text-white text-center'>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>{i18n.language === 'id' ? 'Kami bersemangat dalam menciptakan manfaat yang berkelanjutan untuk klien kami. Kami memberikan inovasi, keahlian, dan hasil ' : 'We are passionate in creating lasting value for our clients. We deliver innovation, reliability, and results'} <span className='font-bold'>{i18n.language === 'id' ? 'yang disesuaikan dengan kebutuhan unik setiap klien.' : "tailored to each client's unique needs"}</span></span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>{i18n.language === 'id' ? 'Kami telah membangun' : "We've built"} <span className='font-bold'>{i18n.language === 'id' ? 'kemitraan tepercaya dengan 10 perusahaan nasional teratas.' : 'trusted partnerships with Top 10 national companies.'}</span> {i18n.language === 'id' ? 'Kami membangun hubungan dengan meluangkan waktu untuk memahami bisnis, tujuan, dan visi klien kami.' : "We build relationships by taking time to understand our client's business, goals, and vision."}</span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}><span className='font-bold'>{i18n.language === 'id' ?  'Dengan 25 tahun pengalaman, kami menawarkan keahlian yang tak tertandingi.' : "With more than 25 years of journey, we offer unparalleled expertise."}</span> {i18n.language === 'id' ? 'Kami dilengkapi dengan wawasan industri yang mendalam dan kemampuan untuk beradaptasi dengan tren yang berkembang, memastikan bahwa klien kami tetap menjadi yang terdepan dalam persaingan pasar.' : "We are equipped with deep industry insights and the ability to adapt to evolving trends, ensuring that you stay ahead of the competition."}</span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}><span className='font-bold'>{i18n.language === "id" ? 'Dengan passion, keteguhan hati, dan virtosity sebagai nilai inti kami,' : "With passion, grit, and virtuosity as our core values,"}</span>{i18n.language === 'id' ? ' Anda akan mendapatkan mitra yang passionate dan berinvestasi dalam kesuksesan Anda.' : " you're gaining a passionate partner invested in your success."}</span>
                    </div>
                </div>
            </div>
        </div>
        {(firstRowArr || secondRowArr) && (
            <div className='h-auto lg:py-44 py-24 flex justify-center items-center w-full relative overflow-x-hidden'>
                <div className='-z-10 absolute w-full flex justify-end items-center'>
                    <img src={accent3} className='w-1/4 h-1/4' style={{animation: `spin linear 20s infinite`}} alt=''/>
                </div>
                <div className='w-full h-auto flex flex-col gap-12 items-center'>
                    <div className='lg:w-4/6 w-11/12 flex flex-col gap-1'>
                        <span className='font-plusjakarta text-blueaccent'>{t("clients.tagline")}</span>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-bluehover'>{t("clients.title")}</span>
                    </div>
                    <div className='w-full h-auto flex flex-col gap-10'>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                        {firstRowArr.map((data,index) => (
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className={data === client7 || data === client8 || data === client10 || data === client12 ? `w-full object-contain` : `h-full object-contain`} alt=''/> </div>
                        ))}
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                        {firstRowArr.map((data,index) => (
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className={data === client7 || data === client8 || data === client10 || data === client12 ? `w-full object-contain` : `h-full object-contain`} alt=''/> </div>
                        ))}
                        </div>
                    </div>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                        {secondRowArr.map((data, index) => (
                            <div className='w-48 h-16 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className='h-full object-contain' alt=''/> </div>
                        ))}
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                        {secondRowArr.map((data, index) => (
                            <div className='w-48 h-16 bg-white rounded-lg shadow-md flex justify-center items-center p-2' key={index}><img src={data} className='h-full object-contain' alt=''/> </div>
                        ))}
                        </div>
                    </div>
                </div>
                </div>
            </div>
            )}
    </div>
    </>
  )
}

export default AboutUsContents