import React from 'react'
import { Link } from 'react-router-dom'
import { Carousel } from 'flowbite-react'
import accent3 from '../assets/Accent3.webp'
import placeholderimg from '../assets/placeholder-image.webp'
import clientbg from '../assets/client-background.webp'
import { MdWork } from "react-icons/md";
import { VscGitStashApply } from "react-icons/vsc";
import { useTranslation } from 'react-i18next'

function CareersContents() {
    const {i18n} = useTranslation()

    const {t} = useTranslation("careers")

    const departments = [
        {
            title: t("IT.title"),
            description: t("IT.description"),
            image: 'IT Department.webp'
        },
        {
            title: t("Project.title"),
            description: t("Project.description"),
            image: 'Project Department.webp'
        },
        {
            title: t("Sales.title"),
            description: t("Sales.description"),
            image: 'Sales Department.webp'
        },
        {
            title: t("Business Development.title"),
            description: t("Business Development.description"),
            image: 'Business Development Department.webp'
        },
        {
            title: t("Marketing.title"),
            description: t("Marketing.description"),
            image: 'Marketing Department.webp'
        },
        {
            title: t("Human Resource.title"),
            description: t("Human Resource.description"),
            image: 'HR Department.webp'
        },
        {
            title: t("Finance.title"),
            description: t("Finance.description"),
            image: 'Finance Department.webp'
        },
        {
            title: t("Administration.title"),
            description: t("Administration.description"),
            image: 'Administration Department.webp'
        }
    ]

    const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-white/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-blueaccent",
          },
          base: "h-2 w-2 rounded-full",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-blueprimary/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 text-blueprimary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };
  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center pt-20'>
        <div className='w-full h-full relative'>
            <div className='lg:w-1/2 lg:h-full w-full absolute flex lg:justify-start justify-center items-end'>
                <img src={accent3} alt='' className='w-96' style={{animation: `spin linear 20s infinite`}}/>
            </div>
            <div className='lg:w-1/3 w-full lg:h-full h-1/4 absolute flex lg:justify-end justify-start items-center p-10'>
                <div className='lg:w-2/3 w-full h-full font-plusjakarta lg:text-4xl text-2xl flex items-center font-bold text-bluehover'>{t("careers.title")}</div>
            </div>
            <div className='w-full h-[600px]'>
                <Carousel pauseOnHover theme={customTheme}>
                    {departments.map((data, index) => (
                    <div key={index} className='w-full h-full lg:flex lg:flex-row justify-end flex flex-col'>
                        <div className='lg:w-2/3 w-full h-3/4 lg:h-full bg-cover bg-center flex flex-col justify-end hover:cursor-pointer relative'>
                            <div className='w-full h-full absolute -z-10'><img src={data.image ? require(`../assets/departments/${data.image}`) : placeholderimg} alt='' className='h-full w-full object-cover'/>  </div>
                            <div className='w-full h-auto font-raleway text-white p-5 pb-10 bg-black bg-opacity-75 flex flex-col gap-5'>
                                <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{data.title}</span>
                                <span className='font-raleway lg:text-2xl'>{data.description}</span>
                            </div>
                        </div>
                    </div>
                    ))}
                </Carousel>
            </div>
        </div>
        <div className='relative h-auto lg:py-40 py-10 flex justify-center items-center w-full bg-cover bg-center' style={{backgroundImage: `url(${clientbg})`, backgroundSize:`cover`}}>
            <div className='absolute bg-white inset-0 opacity-80 w-full h-full'></div>
            <div className='relative lg:w-4/6 w-11/12 h-4/6 flex flex-col lg:gap-12 gap-5'>
                <div className='flex flex-col gap-1 w-full justify-center items-center'>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{t("careers.joinUs")}</span>
                </div>
                <div className='w-full flex justify-center items-center py-10 lg:px-40 px-5' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <span className='font-raleway text-bluehover font-bold text-center lg:text-2xl text-xl'>{t("careers.joinUsText")}</span>
                </div>
            </div>
        </div>
        <div className='w-full flex justify-center items-center py-24'>
            <div className='lg:w-3/6 w-11/12 flex flex-col gap-12 relative'>
                <div className='w-full h-full lg:flex lg:flex-row flex flex-col gap-5 justify-center items-center lg:gap-10'>
                    <Link to={'https://jobs.bajau.com/'} target='_blank' className='w-full h-80 bg-bluehover rounded-xl flex justify-center items-center relative hover:scale-105 hover:shadow-xl hover:cursor-pointer transition-all duration-300'>
                        <div className='h-40 w-40 absolute'><MdWork className='h-full w-full text-white opacity-10'/></div>
                        <span className='text-white font-plusjakarta font-bold lg:text-2xl text-xl relative'>{t("careers.jobOpenings")}</span>
                    </Link>
                    <Link to={'https://forms.gle/yUCZyNN3v6pqamj29'} target='_blank' className='w-full h-80 bg-bluehover rounded-xl flex justify-center items-center relative hover:scale-105 hover:shadow-xl hover:cursor-pointer transition-all duration-300'>
                        <div className='h-40 w-40 absolute'><VscGitStashApply className='h-full w-full text-white opacity-10'/></div>
                        <span className='text-white font-plusjakarta font-bold lg:text-2xl text-xl relative'>{t("careers.sendCV")}</span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CareersContents