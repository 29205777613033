import React, {useState} from 'react'
import blogsAndNewsData from '../helpers/BlogsData.json'
import placeholderimg from '../assets/placeholder-image.webp'
import accent1 from '../assets/Accent1.webp'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function BlogsAndNewsContents() {
    const [hoverIndex, setHoverIndex] = useState(null)

    const {t} = useTranslation("home")

    function custom_sort(a, b) {
        return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime();
    }

    const sortedNews = blogsAndNewsData[0].blogs.sort(custom_sort)
  
  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:py-40 py-24 relative'>
        <div className='w-full h-full absolute flex justify-end items-end bottom-10 right-20'>
            <img src={accent1} alt='' className='w-96' style={{animation: `spin linear 20s infinite`}}/>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col gap-1 mb-12'>
            <span className='font-plusjakarta text-blueaccent'>{t("news.tagline")}</span>
            <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{t("news.title")}</span>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col lg:gap-10 gap-5 relative'>
            {sortedNews.map((data, index) => (
                <Link to={`/news/${data.title}`} className={`w-full lg:h-60 flex lg:gap-5 gap-2 border-b-2 border-blueaccent lg:p-5 py-5 hover:text-bluehover ${hoverIndex === index ? 'scale-105 shadow-xl cursor-pointer' : ''}`} key={index} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                    <div className='lg:w-80 lg:h-40 w-40 h-32 flex justify-center items-center bg-slate-200 rounded-lg'>
                        <img src={data.image ? require(`../assets/${data.image}`) : placeholderimg} alt='' className='w-full h-full object-cover' />
                    </div>
                    <div className='w-full flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-2xl text-xl'>{data.title}</span>
                        <span className='xl:block hidden xl:font-raleway'><p key={index}>
                    {data.text[0].paragraph.map((part, index) => {
                      if (typeof part === 'string') {
                        return part; // Render plain text
                      } else if (part.bold && part.italic) {
                        return <strong key={index}><em>{part.bold}</em></strong>; // Render bold + italic
                      } else if (part.bold) {
                        return <strong key={index}>{part.bold}</strong>; // Render bold
                      } else if (part.italic) {
                        return <em key={index}>{part.italic}</em>; // Render italic
                      } else if (part.orderedList) {
                        return <>
                        {part.orderedList.map((data, index) => (
                            <p key={index}>{data}</p>
                        ))}
                        </>
                      }
                      return null;
                    })}
                  </p></span>
                    </div>
                </Link>
            ))}
        </div>
    </div>
    </>
  )
}

export default BlogsAndNewsContents