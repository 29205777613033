import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import enAboutUs from './locales/en/aboutUs.json'
import idAboutUs from './locales/id/aboutUs.json'
import enNavbar from './locales/en/navbar.json'
import idNavbar from './locales/id/navbar.json'
import enHome from './locales/en/home.json'
import idHome from './locales/id/home.json'
import enSolution from './locales/en/solutions.json'
import idSolution from './locales/id/solutions.json'
import enCareers from './locales/en/careers.json'
import idCareers from './locales/id/careers.json'

const resources = {
    en: {
        aboutUs: enAboutUs,
        navbar: enNavbar,
        home: enHome,
        solutions: enSolution,
        careers: enCareers
    },
    id: {
        aboutUs: idAboutUs,
        navbar: idNavbar,
        home: idHome,
        solutions: idSolution,
        careers: idCareers
    }
}

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug:true,
    lng: 'id',
    resources
})
